<template>
  <!-- 网站建设 -->
  <div class="website" ref="website">
    <div
      class="website-nav"
      ref="websiteNav"
      @mousewheel="mouseWheelHandle"
      @DOMMouseScroll="mouseWheelHandle"
    >
      <div
        v-for="(item, idx) in wzjsList"
        :key="idx"
        class="website-nav-nr"
        :style="{ height: isHeight + 'px' }"
        :id="'currentId' + (idx + 1)"
      >
        <div
          class="website-nav-bg"
          :style="{ backgroundImage: `url(${item.qyjs_bg})` }"
          v-if="idx === 0"
        >
          <div class="qygwjs_nav">
            <div
              class="nav-imgs"
              :style="{
                background: `url(${item.qyjs_img}) right top no-repeat`
              }"
              :class="inAnimation ? 'dh-bounceInLeft' : ''"
            >
              <div class="qygwjs_nav_logo">
                <img
                  :src="item.qyjs_tp"
                  alt=""
                  style="width:100%;height:100%"
                />
              </div>
            </div>
            <div class="nav-texts">
              <div
                class="qygwjs_nav_fadeInUp"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                <h2>{{ item.qyjs }}</h2>
                <span>{{ item.qyjs_title }}</span>
              </div>
              <p
                class="qygwjs_nav_text"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                {{ item.qyjs_text }}
              </p>
              <div
                class="qygwjs_nav_btn"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                <a v-for="(e, x) in btnList" :key="x" :href="e.link"
                  ><span>{{ e.name }}</span></a
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="website-nav-bg"
          :style="{ backgroundImage: `url(${item.ppsj_bg})` }"
          v-if="idx === 1"
        >
          <div class="ppwzsj_nav">
            <div class="nav-texts">
              <div
                class="qygwjs_nav_fadeInUp"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                <h2>{{ item.ppsj }}</h2>
                <span>{{ item.ppsj_title }}</span>
              </div>
              <p
                class="qygwjs_nav_text"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                {{ item.ppsj_text }}
              </p>
              <div
                class="qygwjs_nav_btn"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                <a v-for="(e, x) in btnList" :key="x" :href="e.link"
                  ><span>{{ e.name }}</span></a
                >
              </div>
            </div>
            <img
              v-for="(el, i) in item.ppsj_list"
              :key="i"
              :src="el"
              alt=""
              :class="inAnimation ? 'dh-bounceInRight' : ''"
            />
          </div>
        </div>
        <div
          class="website-nav-bg"
          :style="{ backgroundImage: `url(${item.gwsc_bg})` }"
          v-if="idx === 2"
        >
          <div class="gwscwz_nav">
            <div
              class="nav-imgs"
              :style="{ background: `url(${item.gwsc_img}) no-repeat` }"
              :class="inAnimation ? 'dh-bounceInLeft' : ''"
            >
              <div class="gwscwz_nav_logo">
                <img
                  :src="item.gwsc_tp"
                  alt=""
                  style="width:100%;height:100%"
                />
              </div>
              <img
                v-for="(el, i) in item.gwsc_list"
                :key="i"
                :src="el"
                alt=""
              />
            </div>
            <div class="nav-texts">
              <div
                class="qygwjs_nav_fadeInUp"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                <h2>{{ item.gwsc }}</h2>
                <span>{{ item.gwsc_title }}</span>
              </div>
              <p
                class="qygwjs_nav_text"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                {{ item.gwsc_text }}
              </p>
              <div
                class="qygwjs_nav_btn"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                <a v-for="(e, x) in btnList" :key="x" :href="e.link"
                  ><span>{{ e.name }}</span></a
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="website-nav-bg"
          :style="{ backgroundImage: `url(${item.sjwx_bg})` }"
          v-if="idx === 3"
        >
          <div class="xcxwzjs_nav">
            <div class="nav-texts">
              <div
                class="qygwjs_nav_fadeInUp"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                <h2>{{ item.sjwx }}</h2>
                <span>{{ item.sjwx_title }}</span>
              </div>
              <p
                class="qygwjs_nav_text"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                {{ item.sjwx_text }}
              </p>
              <div
                class="qygwjs_nav_btn"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                <a v-for="(e, x) in btnList" :key="x" :href="e.link"
                  ><span>{{ e.name }}</span></a
                >
              </div>
            </div>
            <div class="nav-imgs-1">
              <img
                :src="item.sjwx_list[0]"
                alt=""
                :class="inAnimation ? 'dh-bounceInDown' : ''"
                class="bgs0"
              />
              <img
                :src="item.sjwx_list[1]"
                alt=""
                :class="inAnimation ? 'dh-bounceInUp' : ''"
                class="bgs1"
              />
            </div>
          </div>
        </div>
        <div
          class="website-nav-bg"
          :style="{ backgroundImage: `url(${item.yxjs_bg})` }"
          v-if="idx === 4"
        >
          <div class="yxwzjs_nav">
            <div
              class="nav-imgs"
              :style="{
                background: `url(${item.yxjs_img}) right top no-repeat`
              }"
              :class="inAnimation ? 'dh-bounceInLeft' : ''"
            >
              <div class="yxwzjs_nav_logo">
                <img
                  :src="item.yxjs_tp"
                  alt=""
                  style="width:100%;height:100%"
                />
              </div>
            </div>
            <div class="nav-texts">
              <div
                class="qygwjs_nav_fadeInUp"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                <h2>{{ item.yxjs }}</h2>
                <span>{{ item.yxjs_title }}</span>
              </div>
              <p
                class="qygwjs_nav_text"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                {{ item.yxjs_text }}
              </p>
              <div
                class="qygwjs_nav_btn"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                <a v-for="(e, x) in btnList" :key="x" :href="e.link"
                  ><span>{{ e.name }}</span></a
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="website-nav-bg"
          :style="{ backgroundImage: `url(${item.hywz_bg})` }"
          v-if="idx === 5"
        >
          <div class="hymhwz_nav">
            <div class="nav-texts">
              <div
                class="qygwjs_nav_fadeInUp"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                <h2>{{ item.hywz }}</h2>
                <span>{{ item.hywz_title }}</span>
              </div>
              <p
                class="qygwjs_nav_text"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                {{ item.hywz_text }}
              </p>
              <div
                class="qygwjs_nav_btn"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                <a v-for="(e, x) in btnList" :key="x" :href="e.link"
                  ><span>{{ e.name }}</span></a
                >
              </div>
            </div>
            <div
              class="nav-imgs-2"
              :class="inAnimation ? 'dh-bounceInRight' : ''"
            >
              <img :src="item.hywz_img" alt="" style="width:100%;height:100%" />
            </div>
          </div>
        </div>
        <div
          class="website-nav-bg"
          :style="{ backgroundImage: `url(${item.xys_bg})` }"
          v-if="idx === 6"
        >
          <div class="xywysj_nav">
            <div
              class="xywysj_nav_logo"
              :class="inAnimation ? 'dh-bounceInDown' : ''"
            >
              <img :src="item.xys_img" alt="" style="width:100%;height:100%" />
            </div>
            <div class="nav-texts">
              <div
                class="qygwjs_nav_fadeInUp"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                <h2>{{ item.xys }}</h2>
                <span>{{ item.xys_title }}</span>
              </div>
              <p
                class="qygwjs_nav_text"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                {{ item.xys_text }}
              </p>
              <div
                class="qygwjs_nav_btn"
                :class="inAnimation ? 'dh-fadeInUp' : ''"
              >
                <a v-for="(e, x) in btnList" :key="x" :href="e.link"
                  ><span>{{ e.name }}</span></a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="website-nav-bg" v-if="idx === 7">
          <div class="footer_nav">
            <div class="footer_nav_dbinfo">
              <ul v-if="yjData.footList.length != 0">
                <li v-for="(item, idx) in yjData.footList" :key="idx">
                  <div class="li_img">
                    <img
                      :src="yjData.footList2[idx].value"
                      alt=""
                      style="width:100%;height:100%"
                    />
                  </div>
                  <h3>{{ item.key }}</h3>
                  <div v-html="item.value"></div>
                </li>
              </ul>
            </div>
            <Footer
              :currentData="currentData"
              @onFanhuiTop="onFanhuiTop"
            ></Footer>
          </div>
        </div>
      </div>
    </div>
    <div class="website-fp-nav">
      <ul>
        <li v-for="(item, idx) in wzjsList" :key="idx">
          <div
            @click="move(idx + 1)"
            class="li_nav"
            :class="fullpage.current == idx + 1 ? 'li_active' : ''"
            v-if="idx != 7"
          >
            <label>{{
              idx === 0
                ? item.qyjs
                : idx === 1
                ? item.ppsj
                : idx === 2
                ? item.gwsc
                : idx === 3
                ? item.sjwx
                : idx === 4
                ? item.yxjs
                : idx === 5
                ? item.hywz
                : item.xys
            }}</label>
            <span
              :style="{
                background: `url(${
                  idx === 0
                    ? item.qyjs_ico
                    : idx === 1
                    ? item.ppsj_ico
                    : idx === 2
                    ? item.gwsj_ico
                    : idx === 3
                    ? item.sjwx_ico
                    : idx === 4
                    ? item.yxjs_ico
                    : idx === 5
                    ? item.hywz_ico
                    : item.xys_ico
                }) 100%/100% no-repeat`
              }"
            ></span>
          </div>
        </li>
      </ul>
    </div>
    <div class="website-nav-2">
      <div
        v-for="(item, idx) in wzjsList"
        :key="idx"
        :class="'website-item' + idx"
        class="website-div"
        :style="{
          backgroundImage: `url(${
            idx === 0
              ? item.qyjs_bg
              : idx === 1
              ? item.ppsj_bg
              : idx === 2
              ? item.gwsc_sj
              : idx === 3
              ? item.sjwx_bg
              : idx === 4
              ? item.yxjs_bg
              : idx === 5
              ? item.hywz_bg
              : item.xys_bg
          })`
        }"
      >
        <img
          :src="
            idx === 0
              ? item.qyjs_sj
              : idx === 1
              ? item.ppsj_sj
              : idx === 2
              ? item.gwsc_sj
              : idx === 3
              ? item.sjwx_sj
              : idx === 4
              ? item.yxjs_sj
              : idx === 5
              ? item.hywz_sj
              : item.xys_sj
          "
          alt=""
          style="width:100%;height:100%"
        />
        <div class="website-item-cons">
          <h2>
            {{
              idx === 0
                ? item.qyjs
                : idx === 1
                ? item.ppsj
                : idx === 2
                ? item.gwsc
                : idx === 3
                ? item.sjwx
                : idx === 4
                ? item.yxjs
                : idx === 5
                ? item.hywz
                : item.xys
            }}
          </h2>
          <div class="cons_zi_title">
            {{
              idx === 0
                ? item.qyjs_title
                : idx === 1
                ? item.ppsj_title
                : idx === 2
                ? item.gwsc_title
                : idx === 3
                ? item.sjwx_title
                : idx === 4
                ? item.yxjs_title
                : idx === 5
                ? item.hywz_title
                : item.xys_title
            }}
          </div>
          <div class="cons_zi_text">
            {{
              idx === 0
                ? item.qyjs_text
                : idx === 1
                ? item.ppsj_text
                : idx === 2
                ? item.gwsc_text
                : idx === 3
                ? item.sjwx_text
                : idx === 4
                ? item.yxjs_text
                : idx === 5
                ? item.hywz_text
                : item.xys_text
            }}
          </div>
          <div class="cons_zi_btn">
            <a v-for="(e, x) in btnList" :key="x" :href="e.link"
              ><span>{{ e.name }}</span></a
            >
          </div>
        </div>
      </div>
    </div>
    <Quickly :currentData="currentData" @onFanhuiTop="onFanhuiTop"></Quickly>
    <Footer :currentData="currentData"></Footer>
  </div>
</template>
<script>
import Footer from '@/common/footer/index-footer.vue'
import Quickly from '@/common/quickly/quickly.vue'
import { getBasicMsg, getPageMsg } from '@/api/api'
export default {
  components: {
    Footer,
    Quickly
  },
  data () {
    return {
      isHeight: 0,
      inAnimation: true,
      fullpage: {
        current: 1, // 当前页面
        isScrolling: false, // 防止滚动多页
        deltaY: 0 // 判断往下往上
      },
      wzjsList: [],
      yjList: [],
      yjData:{},
      btnList: [
        {
          link: '#/moban',
          name: '查看模板'
        },
        {
          link: '',
          name: '联系制作'
        }
      ],
      currentData: {}
    }
  },
  methods: {
    getData () {
      this.isHeight = window.innerHeight
      let that = this
      getBasicMsg(that.changeData()).then(res => {
        if (res.data.code == 1) {
          that.currentData = res.data.data
        }
      })
      let id = sessionStorage.getItem(that.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          let arr = []
          arr.push(res.data.data.qyjs)
          arr.push(res.data.data.ppsj)
          arr.push(res.data.data.gwsc)
          arr.push(res.data.data.sjwx)
          arr.push(res.data.data.yxjs)
          arr.push(res.data.data.hywz)
          arr.push(res.data.data.xys)
          arr.push({})
          that.wzjsList = arr
          that.yjData = res.data.data.foot
          console.log(arr)
        }
      })
    },
    onFanhuiTop () {
      this.move(1)
      this.fullpage.current = 1
    },
    next () {
      // 往下
      let len = this.wzjsList.length
      if (this.fullpage.current + 1 <= len) {
        this.fullpage.current += 1
        this.move(this.fullpage.current)
      }
    },
    pre () {
      // 往上
      if (this.fullpage.current - 1 > 0) {
        this.fullpage.current -= 1
        this.move(this.fullpage.current)
      }
    },
    move (index) {
      this.fullpage.current = index
      this.fullpage.isScrolling = true // 防止滚动多页
      this.directToMove(index)
      setTimeout(() => {
        // 延迟1s
        this.fullpage.isScrolling = false
        this.inAnimation = false
      }, 1010)
    },
    directToMove (index) {
      let height = this.$refs['website'].clientHeight //获取屏幕的宽度
      let scrollPage = this.$refs['websiteNav'] // 获取执行tarnsform的元素
      let scrollHeight
      scrollHeight = -(index - 1) * height + 'px'
      scrollPage.style.transform = `translateY(${scrollHeight})`
      this.inAnimation = true
    },
    mouseWheelHandle (event) {
      // 监听鼠标监听
      // 添加冒泡阻止
      let evt = event || window.event
      if (evt.stopPropagation) {
        evt.stopPropagation()
      } else {
        evt.returnValue = false
      }
      if (this.fullpage.isScrolling) {
        // 判断是否可以滚动
        return false
      }
      let e = event.originalEvent || event
      this.fullpage.deltaY = e.deltaY || e.detail // Firefox使用detail
      if (this.fullpage.deltaY > 0) {
        this.next()
      } else if (this.fullpage.deltaY < 0) {
        this.pre()
      }
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style scoped lang="less">
.website {
  min-height: 100vh;
  overflow: hidden;
  .website-nav {
    width: 100%;
    height: 100vh;
    transition: all linear 0.5s;
    .website-nav-nr {
      height: 100%;
      transition: all linear 0.5s;
      .website-nav-bg {
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        height: 100%;
        .qygwjs_nav,
        .ppwzsj_nav,
        .gwscwz_nav,
        .xcxwzjs_nav,
        .yxwzjs_nav,
        .hymhwz_nav,
        .xywysj_nav {
          height: 100%;
          display: flex;
          align-items: center;
          width: 86%;
          margin: 0 auto;
          overflow: hidden;
          min-width: 1200px;
          position: relative;
          .nav-imgs {
            width: 600px;
            height: 520px;
            background-size: auto 100% !important;
            position: relative;
            .qygwjs_nav_logo {
              width: 184px;
              animation-delay: 0.6s;
              bottom: 3px;
              position: absolute;
              left: 0;
            }
          }
          .nav-texts {
            margin-top: 40px;
            margin-left: 100px;
            max-width: 600px;
            color: #fff;
            .qygwjs_nav_fadeInUp {
              font-size: 50px;
              height: 130px;
              font-weight: 700;
              background: url(~@/assets/image/all/zhanti_ico2.png)
                left bottom no-repeat;
              span {
                font-size: 18px;
              }
            }
            .qygwjs_nav_text {
              line-height: 1.5;
              font-size: 17px;
              margin-top: 30px;
            }
            .qygwjs_nav_btn {
              overflow: hidden;
              margin-top: 30px;
              display: flex;
              a {
                font-size: 15px;
                width: 140px;
                background-position: 115px center;
                height: 38px;
                border-radius: 26px;
                border: 1px solid #fff;
                text-align: left;
                text-indent: 30px;
                background: url(~@/assets/image/all/zhanti_ico1.png)
                  100px center no-repeat;
                margin-right: 18px;
                position: relative;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                span {
                  display: inline-block;
                  position: relative;
                  cursor: pointer;
                }
              }
              a:hover span {
                color: #027ffd;
                transition: all 0.3s;
              }
              a:hover:before {
                width: 100%;
              }
              a:before {
                content: '';
                display: block;
                position: absolute;
                left: 0%;
                top: 0px;
                height: 38px;
                width: 0;
                background: url(~@/assets/image/all/zhanti_ico1_on.png)
                  #fff 100px center no-repeat;
                -webkit-transition: all 0.3s;
                -o-transition: all 0.3s;
                -ms-transition: all 0.3s;
                transition: all 0.3s;
                border-radius: 24px;
              }
            }
          }
        }
        .qygwjs_nav {
          margin-top: 100px;
        }
        .ppwzsj_nav {
          width: 100%;
          .nav-texts {
            margin-top: 0;
            .qygwjs_nav_fadeInUp {
              background: url(~@/assets/image/all/zhuanti2_ico1.png)
                left bottom no-repeat;
            }
          }
          img:nth-child(2) {
            position: absolute;
            height: 49.7%;
            right: 14%;
            top: 7%;
          }
          img:nth-child(3) {
            position: absolute;
            height: 47.6%;
            right: 19%;
            top: 50%;
          }
          img:nth-child(4) {
            position: absolute;
            height: 49.6%;
            right: 0%;
            top: 25%;
          }
        }
        .gwscwz_nav {
          .nav-imgs {
            width: 590px;
            height: 396px;
            background-size: 100% 100% !important;
            margin-bottom: 47px;
            .gwscwz_nav_logo {
              width: 448px;
              height: 322px;
              margin: 0 auto;
              margin-top: 24px;
            }
            img:nth-child(2) {
              position: absolute;
              width: 150px;
              height: 150px;
              top: -48px;
              right: 0;
            }
            img:nth-child(3) {
              position: absolute;
              width: 155px;
              height: 155px;
              left: -15px;
              top: 62px;
            }
            img:nth-child(4) {
              position: absolute;
              width: 181px;
              height: 181px;
              right: -16px;
              bottom: -47px;
            }
          }
        }
        .xcxwzjs_nav {
          .nav-texts {
            margin-left: 0;
            margin-top: 0;
          }
          .nav-imgs-1 {
            width: 50%;
            height: 100%;
            position: relative;
            .bgs0 {
              top: 8%;
              position: absolute;
              left: 0px;
              width: 480px;
            }
            .bgs1 {
              top: 25%;
              position: absolute;
              left: 260px;
              width: 600px;
            }
          }
        }
        .yxwzjs_nav {
          .nav-imgs {
            background-size: 100% 100% !important;
            .yxwzjs_nav_logo {
              width: 555px;
              height: 330px;
              margin: 0 auto;
              margin-top: 20px;
            }
          }
          .nav-texts {
            margin-top: 0;
          }
        }
        .hymhwz_nav {
          .nav-texts {
            margin-top: 0;
            margin-left: 0;
            max-width: 540px;
            .qygwjs_nav_fadeInUp {
              background: url(~@/assets/image/all/zhuanti2_ico1.png)
                left bottom no-repeat;
            }
          }
          .nav-imgs-2 {
            width: 50%;
            height: 64%;
            margin-left: 80px;
          }
        }
        .xywysj_nav {
          .nav-texts {
            margin-left: 0;
            margin-top: 0;
          }
        }
        .footer_nav {
          width: 100%;
          height: 100%;
          background: #333;
          .footer_nav_dbinfo {
            overflow: hidden;
            padding-top: 141px;
            padding-bottom: 40px;
            ul {
              width: 86%;
              max-width: 1600px;
              min-width: 1150px;
              margin: 0 auto;
              display: flex;
              li {
                width: 32%;
                color: #999;
                padding: 0 20px;
                line-height: 1.5;
                .li_img {
                  width: 100%;
                  height: 230px;
                  padding-bottom: 23px;
                }
                h3 {
                  border-bottom: 1px solid #ccc;
                  font-weight: bold;
                  font-size: 20px;
                  margin-bottom: 10px;
                  padding-bottom: 10px;
                  color: #ccc;
                }
                /deep/p {
                  text-align: justify;
                  color: #999;
                  font-size: 14px;
                  line-height: 1.8;
                }
              }
            }
          }
          .index-footer {
            display: block;
          }
        }
      }
    }
  }
  .website-fp-nav {
    position: fixed;
    z-index: 100;
    margin-top: -180px;
    top: 50%;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    ul {
      li {
        width: auto;
        height: 50px;
        margin: 0;
        margin-top: 1px;
        margin-bottom: 2px;
        position: relative;
        .li_nav {
          background: rgba(0, 0, 0, 0.5);
          width: 50px;
          transition: all 0.3s;
          display: block;
          position: relative;
          z-index: 1;
          height: 100%;
          cursor: pointer;
          text-decoration: none;
          label {
            position: absolute;
            left: 0;
            top: 0;
            width: 0px;
            height: 50px;
            line-height: 50px;
            color: #fff;
            text-align: right;
            font-size: 14px;
            cursor: pointer;
            overflow: hidden;
            transition: all 0.3s;
          }
          span {
            display: inline-block;
            width: 50px;
            position: absolute;
            z-index: 1;
            height: 50px;
            border-radius: 0%;
            margin: 0;
            left: 0px;
            top: 0;
            transition: all 0.3s;
          }
        }
        .li_nav:hover {
          background: #e62022;
          width: 180px;
        }
        .li_nav:hover label {
          width: 130px;
        }
        .li_nav:hover span {
          left: 130px;
        }
        .li_active {
          background: #e62022;
        }
      }
      li:hover .li_nav span {
        width: 50px;
        height: 50px;
        margin: 0;
      }
    }
  }
  .website-nav-2 {
    display: none;
    margin-top: 2.6rem;
    .website-div {
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
      color: #fff;
      .website-item-cons {
        margin: 0 auto;
        width: 94%;
        overflow: hidden;
        text-align: center;
        font-size: 0.8rem;
        h2 {
          font-weight: bold;
          font-size: 1rem;
          margin-top: 1%;
          line-height: 1.5;
        }
        .cons_zi_title {
          position: relative;
          overflow: hidden;
          line-height: 1.5;
          text-align: center;
          padding-bottom: 0.6rem;
          margin-top: 0.2rem;
        }
        .cons_zi_title:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          margin-left: -16px;
          width: 32px;
          height: 3px;
          background: #fff;
        }
        .cons_zi_text {
          line-height: 1.2rem;
          padding-top: 0.5rem;
        }
        .cons_zi_btn {
          margin: 1rem auto;
          overflow: hidden;
          a {
            display: inline-block;
            vertical-align: top;
            max-width: 8rem;
            padding: 0.5rem 0;
            width: 35%;
            border: 1px solid #ffffff;
            color: #fff;
            border-radius: 26px;
            font-size: 0.48rem;
            margin-left: 0.3rem;
            margin-right: 0.3rem;
          }
        }
      }
    }
    .website-item1,
    .website-item5 {
      background: #fff !important;
      .website-item-cons {
        color: #333333;
        .cons_zi_title:after {
          background-color: #333 !important;
        }
      }
      .cons_zi_btn {
        a {
          color: #333333 !important;
          border-color: #333333 !important;
        }
      }
    }
    .website-item2 {
      background: #27d1c7 !important;
    }
    .website-item3 {
      background: #373737 !important;
    }
  }
  .index-footer {
    display: none;
  }
}
</style>
